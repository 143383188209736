<template>
  <span v-if="$auth.check({ patients: 'phi' })">
    <template v-if="format=='full_name'">
      {{ firstInitial ? patient.first_name.charAt(0) + '.' : patient.first_name }}
      {{ lastInitial ? patient.last_name.charAt(0) + '.' : patient.last_name }}
    </template>
    <template v-else-if="format=='last_first'">
      {{ lastInitial ? patient.last_name.charAt(0) + '.' : patient.last_name }},
      {{ firstInitial ? patient.first_name.charAt(0) + '.' : patient.first_name }}
    </template>
    <template v-else-if="format=='first_name'">
      {{ firstInitial ? patient.first_name.charAt(0) + '.' : patient.first_name }}
    </template>
    <template v-else-if="format=='last_name'">
      {{ lastInitial ? patient.last_name.charAt(0) + '.' : patient.last_name }}
    </template>
  </span>
  <span v-else>
    <v-chip
      class="text-overline"
      color="grey darken-1"
      dark
      label
      x-small
      v-text="$t('protected')"
    />
  </span>
</template>
<script>
  export default {
    props: {
      patient: {
        type: Object,
        default: () => ({}),
      },
      format: {
        type: String,
        default: 'full_name',
      },
      firstInitial: {
        type: Boolean,
        default: false,
      },
      lastInitial: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
